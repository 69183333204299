"use client";

import { Popover as NimbusPopover } from "@nimbus-ds/components";

import { NIMBUS_POPOVER_ID } from "../../tailwind.config";

type Props = React.ComponentProps<typeof NimbusPopover>;

export default function Popover(props: Props) {
  return <NimbusPopover {...props} data-override-id={NIMBUS_POPOVER_ID} />;
}
